
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutvf5lf9hGCDMeta } from "/opt/render/project/src/pages/about.vue?macro=true";
import { default as admin7VZRLOT0w2Meta } from "/opt/render/project/src/pages/admin.vue?macro=true";
import { default as applySGzxxUbpxIMeta } from "/opt/render/project/src/pages/apply.vue?macro=true";
import { default as blogEbMrUAHrF6Meta } from "/opt/render/project/src/pages/blog.vue?macro=true";
import { default as codeBZNYYlkY4XMeta } from "/opt/render/project/src/pages/code.vue?macro=true";
import { default as consultancyJYSPZrK6klMeta } from "/opt/render/project/src/pages/consultancy.vue?macro=true";
import { default as counsellingyFeI14gWiVMeta } from "/opt/render/project/src/pages/counselling.vue?macro=true";
import { default as counselorPageBfsN6rSukPMeta } from "/opt/render/project/src/pages/counselorPage.vue?macro=true";
import { default as errorANRmYcnhqzMeta } from "/opt/render/project/src/pages/error.vue?macro=true";
import { default as faqKgR8rdA5zoMeta } from "/opt/render/project/src/pages/faq.vue?macro=true";
import { default as formN4llFHwuTWMeta } from "/opt/render/project/src/pages/form.vue?macro=true";
import { default as galleryIIo534oruxMeta } from "/opt/render/project/src/pages/gallery.vue?macro=true";
import { default as homework6zoSZ20z4KMeta } from "/opt/render/project/src/pages/homework.vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91id_93aa32qm9dYQMeta } from "/opt/render/project/src/pages/jobs/[id].vue?macro=true";
import { default as index4gl9yJx2zDMeta } from "/opt/render/project/src/pages/jobs/index.vue?macro=true";
import { default as lifelineG0HOwQ7dJ9Meta } from "/opt/render/project/src/pages/lifeline.vue?macro=true";
import { default as loginjhbtmcfyq0Meta } from "/opt/render/project/src/pages/login.vue?macro=true";
import { default as personalO5shRwXtLMMeta } from "/opt/render/project/src/pages/personal.vue?macro=true";
import { default as practicegA1IBKS9RLMeta } from "/opt/render/project/src/pages/practice.vue?macro=true";
import { default as tuitionvzerT0fjoJMeta } from "/opt/render/project/src/pages/tuition.vue?macro=true";
import { default as tutorPageeuH1IqvNGCMeta } from "/opt/render/project/src/pages/tutorPage.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/render/project/src/pages/about.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: admin7VZRLOT0w2Meta || {},
    component: () => import("/opt/render/project/src/pages/admin.vue")
  },
  {
    name: "apply",
    path: "/apply",
    component: () => import("/opt/render/project/src/pages/apply.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/opt/render/project/src/pages/blog.vue")
  },
  {
    name: "code",
    path: "/code",
    component: () => import("/opt/render/project/src/pages/code.vue")
  },
  {
    name: "consultancy",
    path: "/consultancy",
    component: () => import("/opt/render/project/src/pages/consultancy.vue")
  },
  {
    name: "counselling",
    path: "/counselling",
    component: () => import("/opt/render/project/src/pages/counselling.vue")
  },
  {
    name: "counselorPage",
    path: "/counselorPage",
    component: () => import("/opt/render/project/src/pages/counselorPage.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/opt/render/project/src/pages/error.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/opt/render/project/src/pages/faq.vue")
  },
  {
    name: "form",
    path: "/form",
    component: () => import("/opt/render/project/src/pages/form.vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("/opt/render/project/src/pages/gallery.vue")
  },
  {
    name: "homework",
    path: "/homework",
    component: () => import("/opt/render/project/src/pages/homework.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/pages/index.vue")
  },
  {
    name: "jobs-id",
    path: "/jobs/:id()",
    meta: _91id_93aa32qm9dYQMeta || {},
    component: () => import("/opt/render/project/src/pages/jobs/[id].vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    meta: index4gl9yJx2zDMeta || {},
    component: () => import("/opt/render/project/src/pages/jobs/index.vue")
  },
  {
    name: "lifeline",
    path: "/lifeline",
    component: () => import("/opt/render/project/src/pages/lifeline.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/render/project/src/pages/login.vue")
  },
  {
    name: "personal",
    path: "/personal",
    meta: personalO5shRwXtLMMeta || {},
    component: () => import("/opt/render/project/src/pages/personal.vue")
  },
  {
    name: "practice",
    path: "/practice",
    component: () => import("/opt/render/project/src/pages/practice.vue")
  },
  {
    name: "tuition",
    path: "/tuition",
    component: () => import("/opt/render/project/src/pages/tuition.vue")
  },
  {
    name: "tutorPage",
    path: "/tutorPage",
    component: () => import("/opt/render/project/src/pages/tutorPage.vue")
  }
]